"use strict";

require("lazysizes/plugins/respimg/ls.respimg.js");
require("lazysizes");
var _objectFitImages = _interopRequireDefault(require("object-fit-images"));
require("./js/header");
require("./js/play-button");
require("./js/accordion");
require("./js/searchpage");
require("./js/carousel");
require("./js/unsupported-browser");
require("./js/lazy-hack");
require("./images/logo_tine.svg");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
(0, _objectFitImages.default)(null, {
  watchMQ: true
});
if (document.querySelector('.menu-anchor')) {
  Promise.resolve().then(function () {
    return _interopRequireWildcard(require('./parts/menu-anchor/index'));
  });
}